import React from 'react'
import get from 'lodash/get'
import Seo from '../components/molecules/seo'
import Layout from '../components/molecules/layout'
import { StructuredText } from 'react-datocms'

class Page extends React.Component {
  render() {
    const page = get(this.props, 'pageContext')

    return (
      <Layout>
        <Seo title={page.title} />
        <section className="hero is-primary">
          <div className="hero-body">
            <h1 className="page-title">{page.title}</h1>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-full">
                <main>
                  <div className="page-block-content content">
                    <StructuredText data={page.datoBody}></StructuredText>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Page
